import React, { Fragment } from 'react';
import { Transition, Disclosure } from '@headlessui/react';
import { ChevronDown, Link } from 'lucide-react';

interface FeatureProps {
    categories: {
        name: string;
        href: string;
        imageSrc: string;
    }[];
}

const FeaturedCategories = ({ categories }: FeatureProps) => {
    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold mb-8 text-white">
                CATEGORÍAS
                <div className="w-16 h-1 bg-orange-500 mt-2"></div>
            </h2>

            {/* Desktop Grid */}
            <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {categories.map((category, index) => (
                    <Transition
                        as={Fragment}
                        show={true}
                        enter="transform transition duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transform duration-300 transition ease-in-out"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        key={index}
                    >
                        <div className="group relative bg-orange-700 rounded-lg overflow-hidden hover:bg-gray-700 transition-all">
                            <div className="aspect-square relative">
                                <img
                                    src={category.imageSrc}
                                    alt={category.name}
                                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                />
                            </div>
                            <div className="p-4 text-center">
                                <h3 className="text-sm font-semibold text-white">{category.name}</h3>
                            </div>
                            <a href={category.href} target="_blank" className="absolute inset-0" aria-label={`View ${category.href}`}></a>
                        </div>
                    </Transition>
                ))}
            </div>
        </div>
    );
};

export default FeaturedCategories;