import './App.css';
import Example from './Example';

const App: React.FC = () => {
  return (
    <Example/>
    //<ProductsPage></ProductsPage>
  );
};

export default App;
